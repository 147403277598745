import React, { useState, useEffect } from 'react'
import T from 'prop-types'
import {
  SectionTitle,
  FormikRichTextEditor,
  RichTextEditor,
} from 'gatsby-components'
import { Grid, withStyles } from '@material-ui/core'
import { useMutation } from 'graphql-hooks'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import { Form, Field } from 'formik'

import {
  upsertQuestionnaireTableDataMutation,
  getQuestionnairePartData,
} from '../queries'
import AutoSaveWatchFormik from './AutoSaveWatchFormik'
import SaveChip from './SaveChip'
import useAuthorizedWatch from '../hooks/useAuthorizedWatch'
import { filterOldScores } from '../utils/filter-old-scores'

function getEmptyTableRow(columnsDef) {
  return columnsDef.reduce(
    (initialValues, { key, type }) => ({
      ...initialValues,
      [key]: type === 'link' ? [] : '',
    }),
    {}
  )
}

function getExistingTableData(assessmentTables, tableDef) {
  return (
    assessmentTables &&
    assessmentTables.find(({ table_key }) => table_key === tableDef.key)
  )
}

function getReturnedTableData(queryAction, result, primaryKeys) {
  if (result.error) throw result.error
  const err = msg => {
    throw new Error(`Return from ${queryAction} ${msg}`)
  }

  const expectedProps = `data.${queryAction}.returning`
  const returning = get(result, expectedProps)

  if (!returning) err(`lacks properties ${expectedProps}`)

  const [table] = returning

  if (!table)
    err(`lacks table with pkeys ${JSON.stringify(primaryKeys, null, 2)}`)
  if (!table.table_values)
    err(`lacks .table_values on pkeys ${JSON.stringify(primaryKeys, null, 2)}`)

  return table
}

const Wrapper = withStyles(
  theme => ({
    root: {
      marginTop: theme.spacing(7),
    },
    titleGrid: {
      marginTop: theme.spacing(3.25),
    },
  }),
  { withTheme: true }
)(function({ children, pillarColor, name, classes }) {
  return (
    <Grid container className={classes.root}>
      <Grid item xs={3} className={classes.titleGrid}>
        <SectionTitle barColor={pillarColor}>{name}</SectionTitle>
      </Grid>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  )
})

function AutoSave({
  classes,
  tableDef,
  columnsDef,
  assessmentId,
  criterionKey,
  pillarKey,
  canEdit,
  pillarColor,
  assessment,
  userId,
}) {
  const { i18n } = useTranslation()

  const { fetchedTimestamp, data: partData } = useAuthorizedWatch(
    getQuestionnairePartData,
    {
      id: assessmentId,
      pillarKey,
      userId,
    },
    {
      onPreFetch: variables => !!variables.id,
      onFetch: data => filterOldScores(assessment, data.assessment_by_pk),
    }
  )
  const assessmentTables = partData ? partData.questionnaire_tables : null
  const tableData = getExistingTableData(assessmentTables, tableDef)

  const rowsOrDefault = tableData ? tableData.table_values : []
  const [tableRows, setTableRows] = useState(rowsOrDefault)

  // Update the form state and id anytime the table data changes (i.e. due to watches in the DB).
  // This is required since this component stores table data from props in its state.
  useEffect(() => {
    setTableRows(rowsOrDefault)
  }, [JSON.stringify(rowsOrDefault)])

  const [upsertTableData] = useMutation(upsertQuestionnaireTableDataMutation)

  async function handleSaveTable(rowValues) {
    const primaryKeys = {
      assessmentId,
      pillarKey,
      userId,
      tableKey: tableDef.key,
    }

    const result = await upsertTableData({
      variables: {
        ...primaryKeys,
        tableValues: [rowValues],
      },
    })

    const { table_values: returnedRows } = getReturnedTableData(
      'insert_questionnaire_table',
      result,
      primaryKeys
    )

    setTableRows(returnedRows)
  }

  let tables = [...tableRows]

  if (canEdit || !tables.length) {
    tables.push(getEmptyTableRow(columnsDef))
  }

  const [table] = tables
  const [column] = columnsDef

  return (
    <Wrapper pillarColor={pillarColor} name={tableDef.name}>
      <AutoSaveWatchFormik
        initialValues={table}
        initialValuesTimestamp={fetchedTimestamp}
        onSubmit={(values, actions) => handleSaveTable(values, actions)}
        key={tableDef.key}
      >
        {({ saving }) => (
          <Form className={classes.section}>
            <Grid container direction="column" spacing={2}>
              <Grid item container wrap="nowrap">
                <Grid item xs>
                  <Field
                    id={`${tableDef.key}-${column.key}`}
                    key={column.key}
                    component={FormikRichTextEditor}
                    name={column.key}
                    noDebounce={true}
                    isAssessBase={true}
                    lang={i18n.language}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="flex-end">
                <Grid item>
                  <div className={classes.saveStatus}>
                    <SaveChip dirty={saving} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </AutoSaveWatchFormik>
    </Wrapper>
  )
}

AutoSave.propTypes = {
  theme: T.object.isRequired,
  classes: T.object.isRequired,
  tableDef: T.object.isRequired,
  columnsDef: T.object.isRequired,
  assessmentId: T.number.isRequired,
  criterionKey: T.string.isRequired,
  pillarKey: T.string.isRequired,
  canEdit: T.bool.isRequired,
  pillarColor: T.array.isRequired,
  assessment: T.object.isRequired,
}

function ContextSave({
  classes,
  tableDef,
  columnsDef,
  pillarColor,
  contextChangeHandler,
  contextValue,
}) {
  const { i18n } = useTranslation()

  const [column] = columnsDef

  let typingTimer

  return (
    <Wrapper pillarColor={pillarColor} name={tableDef.name}>
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.section}
      >
        <Grid item container wrap="nowrap">
          <Grid item xs>
            <RichTextEditor
              id={`${tableDef.key}-${column.key}`}
              key={column.key}
              name={column.key}
              isAssessBase={true}
              data={contextValue}
              lang={i18n.language}
              onChange={e => {
                clearTimeout(typingTimer)
                typingTimer = setTimeout(() => contextChangeHandler(e), 1000)
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

ContextSave.propTypes = {
  theme: T.object.isRequired,
  classes: T.object.isRequired,
  tableDef: T.object.isRequired,
  columnsDef: T.object.isRequired,
  contextChangeHandler: T.func.isRequired,
  contextValue: T.string.isRequired,
}

const styles = theme => ({
  section: {
    margin: theme.spacing(3, 0),
  },
  '@global': {
    '.ck-editor__editable_inline': {
      minHeight: '184px',
      alignItems: 'flex-start',
      marginLeft: theme.spacing(4),
    },
  },
})

export default withStyles(styles, {
  withTheme: true,
})(({ withAutoSave, ...props }) =>
  withAutoSave ? <AutoSave {...props} /> : <ContextSave {...props} />
)
