import { useRef } from 'react'
import mean from 'lodash/mean'
import round from 'lodash/round'
import get from 'lodash/get'
import T from 'prop-types'
import { useMutation } from 'graphql-hooks'

import { upsertQuestionnaireScoringDataMutation } from '../queries'

const SLIDER_STEP = 10

/**
 * @param {Object} groupOverall
 * @param {number} cap
 * @return {number}
 */
function getOverallValue(groupOverall, cap) {
  return groupOverall.length
    ? round(mean(groupOverall) / SLIDER_STEP) * SLIDER_STEP
    : 0
}

/**
 * @param {Object} assessmentData
 * @param {Object} scoringDef
 * @param {Object} pillar
 * @return {Object} Scoring id and values
 */
function getScoringData(assessmentData, scoringDef, pillar) {
  const { scoring_values: values } =
    (assessmentData.questionnaire_scores || []).find(
      s => s.pillar_key === pillar.key
    ) || {}
  const scoringValues = scoringDef.reduce(
    (acc, scoringGroup) => ({
      ...acc,
      [scoringGroup.key]: scoringGroup.scores.reduce(
        (acc, score) => ({
          ...acc,
          [score.key]: get(values, `[${scoringGroup.key}][${score.key}]`, 0),
        }),
        {}
      ),
    }),
    {}
  )

  return scoringValues
}

/**
 * @param {number} assessmentId
 * @param {Object} assessmentData
 * @param {Object} pillar
 * @param {Object} scoringDef
 * @param {Object} scoringRules
 * @param {Object} children
 * @return {Object}
 */
export default function QuestionnaireScoringWrapper({
  assessmentId,
  assessmentData = null,
  pillar,
  scoringDef,
  scoringRules,
  saveToDb = true,
  children,
}) {
  const submitRef = useRef({
    onChange: false,
    onChangeCommitted: false,
  })

  const [upsertQuestionnaireScoringData] = useMutation(
    upsertQuestionnaireScoringDataMutation
  )

  if (!saveToDb) {
    return children({
      SLIDER_STEP,
      getOverallValue,
      submitRef,
      scoringRules,
      scoringDef,
    })
  }

  const scoringValues = getScoringData(assessmentData, scoringDef, pillar)

  /**
   * @param {Object} values
   */

  async function handleScoreChange(values, userId) {
    await upsertQuestionnaireScoringData({
      variables: {
        userId,
        pillarKey: pillar.key,
        scoringValues: values,
        assessmentId,
      },
    })
  }

  return children({
    SLIDER_STEP,
    getOverallValue,
    submitRef,
    scoringValues,
    scoringRules,
    scoringDef,
    handleScoreChange,
  })
}

QuestionnaireScoringWrapper.propTypes = {
  assessmentId: T.number.isRequired,
  assessmentData: ({ assessmentData, saveToDb }, propName, componentName) => {
    if (
      saveToDb !== false &&
      !(typeof assessmentData === 'object' && assessmentData !== null)
    ) {
      return new Error(
        `Invalid prop ${propName} supplied to ${componentName}, if saving to Hasura then ${propName} is required`
      )
    }
  },
  pillar: T.object.isRequired,
  scoringDef: T.array.isRequired,
  criterion: T.object.isRequired,
  partNumber: T.number.isRequired,
  saveToDb: T.bool,
}
