export const getIndustries = t => [
  {
    value: 'manufacturingAndProducts',
    name: t('industriesList.item1', 'Manufacturing & Products'),
  },
  {
    value: 'telco',
    name: t('industriesList.item2', 'Telco Media Technologies'),
  },
  {
    value: 'energyAndUtilities',
    name: t('industriesList.item3', 'Energy & Utilities'),
  },
  { value: 'public', name: t('industriesList.item4', 'Public Sector') },
  { value: 'services', name: t('industriesList.item5', 'Services Industry') },
  { value: 'education', name: t('industriesList.item6', 'Education') },
  { value: 'healthcare', name: t('industriesList.item7', 'Healthcare') },
]
