import React, { useState } from 'react'
import T from 'prop-types'
import ReactMarkdown from 'react-markdown'
import classnames from 'classnames'
import { withStyles, Button, Grid } from '@material-ui/core'
import { KeyboardArrowUp } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { SectionTitle } from 'gatsby-components'
const QuestionnairePillarHeader = ({
  classes,
  positioningStatement,
  title,
  pillarColor,
  className,
}) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <Grid
      container
      spacing={2}
      alignItems="space-between"
      className={classnames(className, classes.root)}
    >
      <Grid item xs={4}>
        <SectionTitle
          barColor={pillarColor}
          className={classes.sectionTitle}
          gutterBottom
        >
          {t(title)}
        </SectionTitle>
      </Grid>
      {positioningStatement && (
        <Grid item xs={8}>
          <Button
            color="secondary"
            onClick={toggleOpen}
            className={classes.button}
          >
            {t('Positioning statement')}
            <KeyboardArrowUp
              className={classnames(classes.icon, {
                [classes.invert]: !isOpen,
              })}
            />
          </Button>
        </Grid>
      )}
      <Grid item xs />
      {positioningStatement && (
        <Grid
          item
          xs={12}
          data-testid="expanding-help"
          className={classnames(classes.text, { [classes.hide]: !isOpen })}
        >
          <ReactMarkdown>{t(positioningStatement)}</ReactMarkdown>
        </Grid>
      )}
    </Grid>
  )
}

QuestionnairePillarHeader.propTypes = {
  title: T.string.isRequired,
  positioningStatement: T.string,
  buttonLabel: T.string,
  paginationNode: T.node,
}

const styles = theme => ({
  hide: {
    display: 'none',
  },
  icon: {
    color: theme.palette.secondary.main,
    transition: 'transform 0.4s',
  },
  invert: {
    transform: 'rotate(180deg)',
  },
  text: {},
  root: {
    // Wrestle with MUI's default .MuiGrid-spacing-xs-2 > .MuiGrid-item
    '& $text': {
      padding: theme.spacing(0, 2, 4, 2),
    },
  },
  button: {
    marginTop: theme.spacing(-2),
  },
})

export default withStyles(styles)(QuestionnairePillarHeader)
