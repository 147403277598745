import React, { useContext, useEffect, useState } from 'react'
import {
  Typography,
  withStyles,
  Grid,
  Button,
  Box,
  MenuItem,
} from '@material-ui/core'
import { Link, navigate } from 'gatsby'
import { Formik, Form, Field } from 'formik'
import {
  TextField as FormikTextField,
  Select as FormikSelectField,
} from 'formik-material-ui'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'graphql-hooks'
import { Help as HelpIcon, KeyboardArrowUp } from '@material-ui/icons'
import get from 'lodash/get'
import * as Yup from 'yup'
import classnames from 'classnames'

import {
  AuthContext,
  useAuthorizedQuery,
  PaddedContainer,
  SectionTitle,
  TypedChip,
  BarChart,
  getQuestionnaireChartData,
} from 'gatsby-components'
import {
  createAssessmentMutation,
  getShallowQuestionnaireData,
  getAssessmentContributorsAssessorsData,
  submitAssessmentMutation,
  upsertQuestionnaireNonUserStatusMutation,
} from '../queries'
import { getAssessmentId } from '../utils/url'
import ContextualHelp from '../components/ContextualHelp'
import ContentModal from '../components/ContentModal'
import SEO from '../components/SEO'
import QuestionnairePillarHeader from '../components/QuestionnairePillarHeader'
import { ReportLinks } from '../components/report-links'
import { Redirect } from '@reach/router'
import {
  getCanEditAssesors,
  getCanEditContributors,
} from '../utils/permission-checks'
import { filterOldScores } from '../utils/filter-old-scores'
import Questionnaire2020Scoring from '../components/Questionnaire2020Scoring'
import Questionnaire2020BackgroundInfo from '../components/Questionnaire2020BackgroundInfo'
import { getAssessmentParts } from 'efqm-theme/assessments/getAssessmentParts'
import Questionnaire2020DataContext from '../components/Questionnaire2020DataContext'
import countries from '../data/countries.json'
import { getIndustries } from '../data/industries'
import { getOrganisationSizes } from '../data/organisation-sizes'

function QuestionnaireTemplate({
  location,
  theme,
  classes,
  pageContext: { assessment: contextAssessment, pillarColors },
}) {
  const [modalProps, setModalProps] = useState({
    mdContent: '',
    open: false,
    subTitle: '',
    title: '',
    width: '',
  })

  const { t, i18n } = useTranslation()

  const { assessment } = getAssessmentParts(contextAssessment.key, i18n)

  const assessmentId = getAssessmentId(location)

  const {
    getQuestionnaireData,
    setQuestionnaireData,
    clearQuestionnaireData,
    resetQuestionnaire,
    reloadQuestionnaire,
    getContextDataForChart,
    getLastUsedEmailAddress,
  } = useContext(Questionnaire2020DataContext)

  const questionnaireData = getQuestionnaireData(contextAssessment.key)

  // Use an effect hook to test if page has an assessment ID; this is done
  // to avoid problems with SSR and hydration. Value defaults to 'true' for
  // better page loading experience.
  const [hasAssessmentId, setHasAssessmentId] = useState(true)
  useEffect(() => setHasAssessmentId(!!assessmentId), [assessmentId])

  const {
    isAuthInitialized,
    getUserTokenData,
    getUserAuth,
    saveQuestionnaireToDB,
  } = useContext(AuthContext)
  const { isAdmin, userId, groupId } = getUserTokenData()

  if (
    isAuthInitialized &&
    assessmentId &&
    !saveQuestionnaireToDB(assessmentId)
  ) {
    return <Redirect to="/auth" noThrow />
  }

  const { data: assessmentDBData } = useAuthorizedQuery(
    getShallowQuestionnaireData,
    { id: assessmentId, userId },
    {
      onPreFetch: variables => !!variables.id,
      onFetch: data => filterOldScores(assessment, data.assessment_by_pk),
    }
  )

  const [createAssessment] = useMutation(createAssessmentMutation)
  const [submitAssessment] = useMutation(submitAssessmentMutation)
  const [upsertQuestionnaireNonUserStatus] = useMutation(
    upsertQuestionnaireNonUserStatusMutation
  )

  // data used by barChart
  const [assessmentData, setAssessmentData] = useState(
    assessmentDBData || getContextDataForChart(contextAssessment.key)
  )

  useEffect(() => {
    reloadQuestionnaire(contextAssessment.key)

    return () => clearQuestionnaireData(contextAssessment.key)
  }, [])

  async function handleCreateAssessment({
    country,
    industrySector,
    organisationSize,
    name,
    qwerty: email,
    email: botSubmit,
  }) {
    if (botSubmit) return

    if (saveQuestionnaireToDB(assessmentId)) {
      const { data } = await createAssessment({
        variables: {
          key: assessment.key,
          name,
          owner_id: userId,
          group_id: parseInt(groupId),
          internal: false,
        },
      })
      const id = get(data, 'insert_assessment.returning.0.id')

      navigate(`${location.pathname}#${id}`)
    } else {
      await setQuestionnaireData(contextAssessment.key, {
        questionnaireName: name,
        email,
        country,
        industrySector,
        organisationSize,
        values: pillars.reduce(
          (a, pillar) => ({
            ...a,
            [pillar.key]: pillar.scores.reduce(
              (a, score) => ({ ...a, [score.key]: 0 }),
              {}
            ),
          }),
          {}
        ),
        tables: pillars.reduce(
          (a, pillar) => ({
            ...a,
            [pillar.key]: '',
          }),
          {}
        ),
      })
    }
  }

  const { data: assessorsData } = useAuthorizedQuery(
    getAssessmentContributorsAssessorsData,
    { assessmentId },
    { onPreFetch: variables => !!variables.assessmentId }
  )

  const canAssignContributors = getUserAuth('group-admin')
    ? true
    : (isAdmin && getCanEditAssesors(groupId, assessmentDBData)) ||
      getCanEditContributors(groupId, assessmentDBData)

  const contributors = get(assessorsData, 'contributors', [])

  const headers = [
    { id: 'id', label: 'ID' },
    { id: 'email', label: 'Email' },
    { id: 'group', label: 'Group' },
  ]
  if (canAssignContributors) {
    headers.push({ id: 'contributor', label: 'Contributor' })
  }

  const { pillars } = assessment

  useEffect(() => {
    if (assessmentDBData) {
      setQuestionnaireData(contextAssessment.key, {
        questionnaireName: assessmentDBData ? assessmentDBData.name : null,
        values: pillars.reduce((a, pillar) => {
          let scores = assessmentDBData
            ? assessmentDBData.questionnaire_scores.find(
                score => score.pillar_key === pillar.key
              )
            : undefined

          return {
            ...a,
            [pillar.key]: pillar.scores.reduce(
              (a, score) => ({
                ...a,
                [score.key]:
                  scores &&
                  scores.scoring_values &&
                  scores.scoring_values[pillar.key]
                    ? scores.scoring_values[pillar.key][score.key]
                    : 0,
              }),
              {}
            ),
          }
        }, {}),
      })
    }
  }, [assessmentDBData])

  const { values = {}, tables, questionnaireName } = questionnaireData

  const sliderChangeHandler = pillarKey => (scoreKey, val) => {
    values[pillarKey][scoreKey] = val
    setQuestionnaireData(contextAssessment.key, {
      ...questionnaireData,
      values,
    })
    setAssessmentData(getContextDataForChart(contextAssessment.key))
  }

  const textChangeHandler = pillarKey => val => {
    setQuestionnaireData(contextAssessment.key, {
      ...questionnaireData,
      tables: { ...tables, [pillarKey]: val },
    })
  }

  const assessmentValidationSchema = saveQuestionnaireToDB(assessmentId)
    ? Yup.object().shape({
        name: Yup.string().required(),
      })
    : Yup.object().shape({
        name: Yup.string().required(),
        qwerty: Yup.string()
          .email()
          .required(),
        country: Yup.string().required(),
        industrySector: Yup.string().required(),
        organisationSize: Yup.string().required(),
      })

  const industries = getIndustries(t)
  const orgSizes = getOrganisationSizes(t)

  return (
    <>
      <ContentModal
        onClose={() => {
          setModalProps({ ...modalProps, open: false })
        }}
        {...modalProps}
      />
      <SEO title={get(assessmentData, 'name', 'Assessment')} />
      <PaddedContainer data-testid="assessment">
        <Button component={Link} to="/" variant="text" color="secondary">
          ◀ {t('Assess base home')}
        </Button>
        <div
          className={classes.section}
          data-testid="assessment__key-information"
        >
          <Grid container spacing={4} className={classes.topGrid}>
            <Grid
              item
              xs={9}
              container
              direction="column"
              wrap="nowrap"
              spacing={4}
            >
              <Grid item container>
                <Grid item xs={4}>
                  <SectionTitle
                    barColor={theme.palette.secondary.main}
                    className={classes.sectionTitle}
                    gutterBottom
                  >
                    {assessment.name}
                    {assessment.guidance && (
                      <ContextualHelp helpContent={assessment.guidance}>
                        <HelpIcon
                          color="secondary"
                          className={classes.helpIcon}
                        />
                      </ContextualHelp>
                    )}
                  </SectionTitle>
                </Grid>
                <Grid item xs />
              </Grid>
              <Grid item xs>
                <Grid
                  className={
                    !hasAssessmentId && !questionnaireName
                      ? classes.displayNone
                      : null
                  }
                  container
                  direction="column"
                  spacing={1}
                >
                  <Grid item xs>
                    <Typography variant="h2" color="primary">
                      {hasAssessmentId
                        ? questionnaireName || t('Loading...')
                        : questionnaireName}
                    </Typography>
                  </Grid>
                </Grid>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: '',
                    qwerty: getLastUsedEmailAddress(),
                    country: '',
                    industrySector: '',
                    organisationSize: '',
                  }}
                  validationSchema={assessmentValidationSchema}
                  onSubmit={handleCreateAssessment}
                >
                  {({ isValid, validateForm }) => {
                    useEffect(() => {
                      // isValid defaults to true on mount, bug with Formik
                      // https://github.com/jaredpalmer/formik/issues/1950
                      ;(() => validateForm())()
                    }, [])

                    return (
                      <Form>
                        <Grid
                          container
                          spacing={4}
                          className={
                            hasAssessmentId || questionnaireName
                              ? classes.displayNone
                              : null
                          }
                        >
                          {!saveQuestionnaireToDB(assessmentId) && (
                            <>
                              <Grid item xs={6}>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <Typography variant="h4">
                                      {t('Enter your email address')}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Field
                                      name="qwerty"
                                      component={FormikTextField}
                                      fullWidth
                                      FormHelperTextProps={{
                                        classes: { root: classes.displayNone },
                                      }}
                                    />
                                    <Field
                                      name="email"
                                      // Field for honeypot
                                      component={FormikTextField}
                                      fullWidth
                                      className={classes.displayNone}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={6} />

                              <Grid item xs={6}>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <Typography variant="h4">
                                      {t(
                                        'questionnaire.2020.basedIn',
                                        'Where are you based'
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Field
                                      name="country"
                                      component={FormikSelectField}
                                      fullWidth
                                    >
                                      {countries.map(({ name, code }) => (
                                        <MenuItem value={code} key={code}>
                                          <Typography component="span">
                                            {name}
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={6} />

                              <Grid item xs={6}>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <Typography variant="h4">
                                      {t(
                                        'questionnaire.2020.industrySector',
                                        'What is your industry sector?'
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Field
                                      name="industrySector"
                                      component={FormikSelectField}
                                      fullWidth
                                    >
                                      {industries.map(({ name, value }) => (
                                        <MenuItem value={value} key={value}>
                                          <Typography component="span">
                                            {name}
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={6} />

                              <Grid item xs={6}>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <Typography variant="h4">
                                      {t(
                                        'questionnaire.2020.organisationSize',
                                        'What is the size of your organisation?'
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Field
                                      name="organisationSize"
                                      component={FormikSelectField}
                                      fullWidth
                                    >
                                      {orgSizes.map(({ name, value }) => (
                                        <MenuItem value={value} key={value}>
                                          <Typography component="span">
                                            {name}
                                          </Typography>
                                        </MenuItem>
                                      ))}
                                    </Field>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={6} />
                            </>
                          )}
                          <Grid item xs>
                            <Grid container direction="column" spacing={1}>
                              <Grid item>
                                <Typography variant="h4">
                                  {t('Enter your questionnaire name')}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Field
                                  name="name"
                                  component={FormikTextField}
                                  fullWidth
                                  FormHelperTextProps={{
                                    classes: { root: classes.displayNone },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs>
                            <Grid container direction="column" spacing={1}>
                              <Grid item>
                                <Typography variant="h4">&nbsp;</Typography>
                              </Grid>
                              <Grid item>
                                <Button
                                  type="submit"
                                  color="secondary"
                                  variant="contained"
                                  disabled={!isValid}
                                >
                                  {t('Create Questionnaire')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Form>
                    )
                  }}
                </Formik>
              </Grid>
              {canAssignContributors && (
                <Grid
                  container
                  className={!hasAssessmentId ? classes.displayNone : null}
                >
                  <Grid item xs={12} className={classes.participants}>
                    {contributors.map(({ contributor }) =>
                      contributor ? (
                        <TypedChip
                          key={contributor.id}
                          name={`${contributor.first_name} ${contributor.last_name}`}
                          type="Contributor"
                          color="secondary"
                        />
                      ) : null
                    )}
                  </Grid>
                  <Grid item xs>
                    <Button
                      variant="outlined"
                      color="secondary"
                      component={Link}
                      to={`/assessment/${assessment.key}/contributors#${assessmentId}`}
                      className={classes.assignButton}
                    >
                      {t('Assign Contributors')}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {questionnaireName && !assessmentId && (
                <Grid container>
                  <Grid item xs>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => resetQuestionnaire(contextAssessment.key)}
                      className={classes.assignButton}
                    >
                      {t('reset questionnaire')}
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={9}>
                <Typography variant="body2">
                  {assessment.shortDescription}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={3}
              container
              className={classes.filesSeparator}
              direction="column"
            >
              <Box className={classes.sideBar}>
                <ReportLinks
                  assessment={assessmentData}
                  canViewFeedbackReport={true}
                  canViewManagementReport={false}
                  canViewFeedbackReportAtAnyProgressStage={
                    !!assessmentData.name
                  }
                />
                {assessmentData && questionnaireName && (
                  <>
                    <SectionTitle
                      barColor={theme.palette.primary.dark}
                      className={classnames(
                        classes.sectionTitle,
                        classes.scoringSumTitle
                      )}
                      gutterBottom
                    >
                      {t('Scoring Summary')}
                    </SectionTitle>
                    <BarChart
                      chartData={getQuestionnaireChartData(
                        assessment,
                        assessmentData,
                        pillarColors
                      )}
                    />
                  </>
                )}
              </Box>
            </Grid>
            {!questionnaireName ? (
              <div className={classes.root} data-testid="criterion-part">
                {hasAssessmentId && (
                  <PaddedContainer className={classes.paddedContainer}>
                    <Grid container spacing={2} wrap="nowrap">
                      <Grid item>
                        <Typography variant="h3">{t('Loading...')}</Typography>
                      </Grid>
                    </Grid>
                  </PaddedContainer>
                )}
              </div>
            ) : (
              <Grid item xs={12}>
                {pillars.map((pillar, i) => (
                  <Grid container key={i}>
                    <Grid item xs={12}>
                      <QuestionnairePillarHeader
                        positioningStatement={pillar.description}
                        title={pillar.name}
                        pillarColor={pillarColors[i]}
                      />
                      <Questionnaire2020Scoring
                        assessmentDef={assessment}
                        pillarColor={pillarColors[i]}
                        values={values}
                        changeHandler={sliderChangeHandler(pillar.key)}
                        scoringDef={[pillar]}
                        pillar={pillar}
                        assessmentData={assessmentData}
                        saveToDb={saveQuestionnaireToDB(assessmentId)}
                        assessmentId={assessmentId}
                        userId={userId}
                      />
                      <Questionnaire2020BackgroundInfo
                        tableDef={pillar.tables[0]}
                        columnsDef={pillar.tables[0].columns}
                        key={pillar.tables[0].key}
                        assessmentId={assessmentId}
                        criterionKey={pillar.key}
                        pillarKey={pillar.key}
                        canEdit={true}
                        pillarColor={pillarColors[i]}
                        assessment={assessment}
                        withAutoSave={hasAssessmentId}
                        contextValue={tables ? tables[pillar.key] : null}
                        contextChangeHandler={textChangeHandler(pillar.key)}
                        userId={userId}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
            {questionnaireName && (
              <Grid item container spacing={2} justify="flex-end">
                <Grid item>
                  <Button
                    color="secondary"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {t('Return to top')}
                    <KeyboardArrowUp />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={async () => {
                      if (saveQuestionnaireToDB(assessmentId)) {
                        if (assessmentDBData.status === 'in-progress') {
                          submitAssessment({
                            variables: {
                              id: assessmentId,
                              updatedAt: new Date(),
                            },
                          })
                        }
                      } else {
                        await upsertQuestionnaireNonUserStatus({
                          variables: {
                            email: questionnaireData.email,
                            key: contextAssessment.key,
                            status: 'submitted',
                            title: questionnaireName,
                          },
                        })
                      }

                      navigate(
                        `/assessment/${assessment.key}/feedback-report${
                          assessmentId ? `#${assessmentId}` : ''
                        }`
                      )
                    }}
                  >
                    {t('Complete questionnaire')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </PaddedContainer>
    </>
  )
}

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  paddedContainer: {
    flex: 1,
  },
  section: {
    margin: theme.spacing(3, 0),
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  scoringSumTitle: {
    marginBottom: theme.spacing(-3),
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
  },
  sideBar: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: theme.spacing(3),
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
    width: '100%',
  },
  assignButton: {
    marginLeft: '16px',
  },
  filesSeparator: {
    borderLeft: `solid 1px ${theme.palette.background.light}`,
  },
  topGrid: {
    marginBottom: theme.spacing(18),
  },
  displayNone: {
    display: 'none',
  },
  participants: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginRight: -theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
})

export default withStyles(styles, { withTheme: true })(QuestionnaireTemplate)
